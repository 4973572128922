@import "modules/responsive-type.scss";

$primary: #d8d8d8;/* MAIN COLOR */
$secondary: #444; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
$circlecolor: #d38200;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Comfortaa|Kanit|Lato');
// font-family: 'Kanit', sans-serif;
// font-family: 'Comfortaa', cursive;
// font-family: 'Lato', sans-serif;
html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Comfortaa', cursive;
    font-size: 1rem;
}

h1 { 
    font-family: 'Kanit', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
    font-family: 'Kanit', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
    font-family: 'Kanit', sans-serif;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background-color: #444;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
            font-family: 'Kanit', sans-serif;
            text-align: center;
            margin-top: 36px;
            display: flex;
            align-items: center;
            color: $wht;
             border-radius: $border-radius;
            @media (max-width: 767px) {
                margin-left: 8px;
                margin-top: 0;
                padding: 6px 15px;
                display: inline-block;
                text-align: center;
            }
            &:focus,
            &:active {
                // background: transparent;
                background: $primary;
                color: $blk;
                outline: 0;
            }
            &:hover {
                border-radius:$border-radius;
                background: $primary;
                color: $blk;
                @media (max-width: 767px) {
                    // background: transparent;
                    padding: 6px 15px;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 42px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.navbar-collapse {
    // padding-right: 15px;
    // padding-left: 15px;
    // overflow-x: visible;
    // -webkit-overflow-scrolling: touch;
    border-top: 0px solid transparent;
    // -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    // box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
}

/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;

    .close {display: none;}

    .modal-content {
        color: $primary;

        h2 {
            text-align:center;
            font-size: 25px;
        }
    }

    a.btn, a.btn-default, button {
        @include btn
        margin: 0;
        display: block;
    }
}

.modal-header, .modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}



/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $secondary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 145px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 135px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 100px;
    }
    .navbar-toggle {
        margin-top: 25px;
    }
}

.top-pad {
    padding: 3em 0em; 
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 767px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

// Start language toggle //

body {
  // background:#047c8d;
  // font-family:'Lato';
  // font-weight:100;
   // font-size:22px;
  // color:#f1f1f1;
}
h1 {
  // padding:0px 20px;
  // font-weight:100;
}
p {
  // width:80%;
  // padding:0px 20px;
  // line-height:1.5;
}

.lang{
    
    float: left;
}

#lang-menu-sm{

  display: none;
  // width:55px;
  // padding-bottom: 25px;
  height:auto;
  // position: inherit;
  // position:absolute;
  position: fixed;
  // top:20px;
  // right:20px;
  // border:1px solid rgba(255,255,255,1);
  transition:all .3s ease;
  -webkit-transition:all .3s ease;
  -moz-transition:all .3s ease;
  -o-transition:all .3s ease;

     @media (max-width: 767px) {
        display:block;
        margin-top:47px;
    }

     @media (max-width: 600px) {
        margin-top:30px;
    }

}

#lang-menu-sm .lang {
     border-radius: 5px;
    float: left; 
    // height:25px;
    text-align:center;
    padding:0px 18px;
    border:1px solid rgba(255,255,255,1);
    transition:all .2s ease;
    -webkit-transition:all .2s ease;
    -moz-transition:all .2s ease;
    -o-transition:all .2s ease;

    font-family:'Lato';
    font-weight:100;
    font-size:16px;
    color:#f1f1f1;

    h1 {
      // padding:0px 20px;
      font-weight:100;
    }
    p {
      width:80%;
      // padding:0px 20px;
      line-height:1.5;
    }

    &:hover{
         background-color:#333;
    }

}

#lang-menu {
    border-radius: 5px;
  margin-top: 36px;
  width:55px;
  padding-bottom: 25px;
  height:auto;
  // position: inherit;
  // position:absolute;
  position: fixed;
  // top:20px;
  // right:20px;
  border:1px solid rgba(255,255,255,1);
  transition:all .3s ease;
  -webkit-transition:all .3s ease;
  -moz-transition:all .3s ease;
  -o-transition:all .3s ease;

    @media (max-width: 767px) {
        display: none;
    }

}

.cls-border-lang {
  border:1px solid rgba(255,255,255,.1) !important;
}
#lang-menu div {
    height:25px;
    text-align:center;
    padding:10px;
    border-bottom:1px solid rgba(255,255,255,0);
    transition:all .2s ease;
    -webkit-transition:all .2s ease;
    -moz-transition:all .2s ease;
    -o-transition:all .2s ease;

    font-family:'Lato';
    font-weight:100;
    font-size:22px;
    color:#f1f1f1;

    h1 {
      padding:0px 20px;
      font-weight:100;
    }
    p {
      width:80%;
      padding:0px 20px;
      line-height:1.5;
    }

}

.cls-borderbottom-lang {
  border-bottom:1px solid rgba(255,255,255,.1) !important;
  opacity:.2;
}
#lang-menu ul {
  list-style:none;
  margin:0px;
  padding:0px;
  display:none; 
  text-align:center;
  height: 77px;
    // padding-top: 0px;
    margin-top: 30px;
}

#lang-menu ul li {
    padding:10px 0px;
    transition:all .3s ease;
    -webkit-transition:all .3s ease;
    -moz-transition:all .3s ease;
    -o-transition:all .3s ease;

    font-family:'Lato';
    font-weight:100;
    font-size:22px;
    color:#f1f1f1;
    background-color: #444;



    h1 {
      padding:0px 20px;
      font-weight:100;
    }
    p {
      width:80%;
      padding:0px 20px;
      line-height:1.5;
    }

    a{
        color:#f1f1f1;
        text-decoration: none;
    }

    &:hover{
        cursor:pointer;
        color:#fff;
        // background:rgba(255,255,255,.1);
        background-color:#333;
    }
}



// End language toggle //

#square {
    width: 275px;
    height: 275px;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    border-radius: 150px;
    
    @media (max-width: 1290px) {
        width: 225px;
        height: 225px;
        -moz-border-radius: 175px;
        -webkit-border-radius: 175px;
        border-radius: 175px;
    }

    @media (max-width: 991px) {
        width: 150px;
        height: 150px;
        -moz-border-radius: 125px;
        -webkit-border-radius: 125px;
        border-radius: 125px;
        margin-bottom: 40px;
    }

    @media (max-width: 767px) {
        width: 225px;
        height: 225px;
        -moz-border-radius: 125px;
        -webkit-border-radius: 125px;
        border-radius: 125px;
        margin-bottom: 40px;
    }


}

.shadowthis {
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}

.shadowthat {
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
}

.borderthis{
    border: 10px solid #fff;
    border-radius: 10px;
}

.intro{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/splaat.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 400px 0px;

    @media (max-width: 1366px) {
         background-position: 30% 50%;
        padding: 300px 0px;
    }
    @media (max-width: 991px) {
        padding: 300px 0px;
    }
    @media (max-width: 767px) {
        padding: 200px 0px;
    }
    @media (max-width: 568px) {
        padding: 150px 0px;
    }
    @media (max-width: 414px) {
         background-position: 20% 50%;
        padding: 150px 0px;
    }
    @media (max-width: 320px) {
         background-position: 25% 50%;
        padding: 100px 0px;
    }

    h1{
        color:#fff;
    }
}

.services{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5));
    padding: 15px 0px;
    border-bottom-right-radius:25px; 
    border-bottom-left-radius:25px; 

    .thumb1{
        background: url(../img/img1.png);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        // background-color: $primary;
        border: 5px solid $circlecolor;
    }
    .thumb2{
        background: url(../img/img2.png);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        // background-color: $primary;
        border: 5px solid $circlecolor;
    }
    .thumb3{
        background: url(../img/img3.png);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        // background-color: $circlecolor;
        border: 5px solid $circlecolor;
    }
    .thumb4{
        background: url(../img/img4.png);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        // background-color: $circlecolor;
        border: 5px solid $circlecolor;
    }

    .col-xxs-12 {
        @media (max-width: 500px){
            width: 100%;
        }
    }

    p{
        font-size: 16px;
    }

}


.contact {
    // max-width: 1366px;
    // margin: 0px auto;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5));
    padding: 50px 0px;
    border-top-right-radius:25px; 
     border-top-left-radius:25px; 

    h1,h2{
        color: #444;
    }

    p {
        color:000;
    }

    a {
        color:#000;
        text-decoration: none;
    }
}

i.fa {
    font-size: 100px;
}

.marBot {
    margin-bottom: 25px;
}

.map {
    width: 100%;
    height: 300px;
    padding: 0px;
    /* position: relative; */
    /* z-index: 0; */
}
